<template>
  <v-dialog
    v-model="isVisible"
    width="700"
    content-class="copy-code-dialog"
    @click:outside="onClose"
  >
    <v-card class="copy-code-card">
      <v-card-title class="d-flex justify-center">
        <span> Code kopieren und in den Quellcode Ihrer Website einfügen</span>
        <v-btn small icon :color="color" @click="onClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text>
        {{ iframe }}
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="copyIframe"
          :color="color"
          v-if="!isCopied"
          class="action-btn"
        >
          Kopieren
        </v-btn>
        <v-btn
          @click="onClose"
          v-else
          :color="selectedColor"
          class="action-btn"
        >
          Code kopiert!
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  BASE_BLACK_COLOR,
  SELECTED_COLOR,
} from '../../../../../src/utils/constants';

export default {
  name: 'CopyIframeDialog',
  props: {
    iframe: {
      type: String,
      default: '',
    },
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      color: BASE_BLACK_COLOR,
      selectedColor: SELECTED_COLOR,
      isCopied: false,
    };
  },
  computed: {
    isVisible: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit('changeIframeDialog', val);
      },
    },
  },
  methods: {
    onClose() {
      this.$emit('changeIframeDialog', false);
      setTimeout(() => {
        this.isCopied = false;
      }, 200);
    },
    async copyIframe() {
      try {
        await navigator.clipboard.writeText(this.iframe);
        this.isCopied = true;
      } catch (e) {
        this.isCopied = false;
        this.setSnackBarData({
          text: 'Cannot copy',
          show: true,
          timeout: 3000,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.copy-code-dialog {
  border-radius: 10px !important;
}
.copy-code-card {
  border-radius: 20px !important;
  [class~='v-card__title'] {
    position: relative;
    button {
      position: absolute;
      top: 5px;
      right: 5px;
    }
  }
  [class~='v-card__text'] {
    padding: 20px 45px !important;
  }
  [class~='v-card__actions'] {
    padding: 0 !important;
    .action-btn {
      color: #fff;
      width: 100%;
      text-align: center;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      font-weight: bold;
      font-size: 18px;
      height: 46px;
    }
  }
}
</style>
